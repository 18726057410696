import internalUrls from '@/constants/internalUrls';

import type { ProductPinfinanceItem } from './types';
import { ProductPinfinance } from './types';

export const productPinfinance: ProductPinfinanceItem[] = [
  {
    product: ProductPinfinance.ACD,
    icon: 'https://pinhome-web-assets.imgix.net/icon-komisi-instan.png',
    desc: 'Solusi Untuk Rekan Agen Properti untuk mendapat komisi penjualan properti lebih cepat',
    title: 'Komisi Instan',
    detailsItem: [
      'Pencairan komisi hingga 30% tanpa limit',
      'Komisi diterima dalam 3 hari sejak pengajuan',
      'Tenor pengembalian panjang, hingga 6 bulan',
      'Biaya layanan yang rendah dimulai dari 2.5% perbulan'
    ],
    ctaAjukanLinkDesktop: 'https://play.google.com/store/apps/details?id=id.pinhome.agent&hl=en&gl=US',
    ctaAjukanLinkMobile: 'https://rekan.pinhome.id/app/komisi-instan',
    detailLink: '/komisi-instan'
  },
  {
    product: ProductPinfinance.BL,
    icon: 'https://pinhome-web-assets.imgix.net/icon-bridging-loan.png',
    // eslint-disable-next-line max-len
    desc: 'Solusi pinjaman jangka pendek untuk pengembang perumahan dan bisnis sejenis sebagai modal kerja untuk membiayai proyek konstruksi dan non-konstruksi.',
    title: 'Bridging Loan',
    detailsItem: [
      'Pinjaman dana sampai dengan 2 miliar',
      'Dapat digunakan untuk pembiayaan pembangunan properti dan non-konstruksi',
      'Tenor pengembalian mulai dari 1 hingga 12 bulan',
      'Biaya layanan yang rendah dimulai dari 1.33% perbulan'
    ],
    ctaAjukanLinkDesktop: internalUrls.blSubmission,
    ctaAjukanLinkMobile: internalUrls.blSubmission,
    detailLink: '/bridging-loan'
  }
];
