import type { HelpSectionProps } from '@/components/shared/LandingPage/HelpSection/types';


export const pinfinanceHelpSection: HelpSectionProps = {
  title: 'Butuh Bantuan?',
  description: 'Hubungi kami atau kunjungi pusat bantuan untuk bantuan lebih lanjut tentang Pinhome Financial'
};

export const metaTitle = 'Pinhome Financial';
export const metaDescription = `Layanan Pinhome Financial hadir untuk memberikan dukungan finansial 
kepada para pelaku usaha properti. Simak selengkapnya di sini!`;

