/* eslint-disable max-len */
export type TestimonialObject = {
  image: string;
  name: string;
  companyAndUseProduct: string;
  testimonial: string;
};

export const testimonialList: TestimonialObject[] = [
  {
    name: 'Ester Pribadi',
    image: 'https://pinhome-web-assets.imgix.net/acd-test-ester.png',
    companyAndUseProduct: 'AgenBee Property - Pengguna Komisi Instan',
    testimonial: 'Komisi Instan Pinhome sangat membantu untuk kebutuhan & perputaran cashflow, memudahkan kita mendapatkan komisi yang masih lama jatuh tempo. Jadi semangat jaga transaksi, kan sudah dapat komisi di awal.'
  },
  {
    name: 'Pangky Winata',
    image: 'https://pinhome-web-assets.imgix.net/acd-test-panky.png',
    companyAndUseProduct: 'Agen Savoy Real Estate - Pengguna Komisi Instan',
    testimonial: 'Program Pinhome bagus banget untuk agen, membantu mencairkan komisi lebih cepat dan prosesnya juga ga ribet'
  },
  {
    name: 'Arief Souwiko',
    image: 'https://pinhome-web-assets.imgix.net/agent-app/images/testimonial-acd/testimonial-3.jpg',
    companyAndUseProduct: '9Pro - Pengguna Komisi Instan',
    testimonial: 'Sangat terbantu oleh program Komisi Instan Pinhome karena ga perlu menunggu komisi berbulan-bulan. Saya jadi makin semangat untuk cepet closing karena komisinya bisa didapatkan di awal'
  },
  {
    name: 'Sandra Widjaya',
    image: 'https://pinhome-web-assets.imgix.net/agent-app/images/testimonial-acd/testimonial-4.jpg',
    companyAndUseProduct: 'Berkat Property - Pengguna Komisi Instan',
    testimonial: 'Program Komisi Instan Pinhome sangat menguntungkan untuk kami para agen. Di samping prosesnya yang ga ribet, pencairannya juga cepat. Dengan program ini kami sebagai agen sangat terbantu'
  }
];
