import { useCallback } from 'react';

import { IconButton } from '@pinvest/sirius-button';
import { Close20Icon } from '@pinvest/sirius-icons';
import Popup from '@pinvest/sirius-popup/dist/popup';
import Image from 'next/image';

import Text from '@/components/shared/Text';
import { useParsedUserAgent } from '@/contexts/parsedUserAgent';
import cookie from '@/services/cookies';
import { signInEvent } from '@/services/eventTracking/events/signIn/constants';
import { SignInAttr } from '@/services/eventTracking/events/signIn/types';

import { productPinfinanceModal } from './config';
import style from './style.module.css';
import type { ChooseProductModalProps, ProductPinfinanceModalItem } from './types';

const ChooseProductModal = ({
  isModalChooseProductOpen = false,
  modalChooseProductToogler
}: ChooseProductModalProps) => {

  const { isMobile } = useParsedUserAgent();

  const closeModal = useCallback(() => {
    modalChooseProductToogler(false);
  }, [modalChooseProductToogler]);

  const handleSingInOnClicked = (product: ProductPinfinanceModalItem) => {
    if (product.title === 'Bridging Loan') {
      cookie.add({
        name: signInEvent.openPage,
        value: JSON.stringify({ attribute: SignInAttr.NAV_BAR_SIGN_IN })
      });
    }
  };

  return (
    <Popup isOpen={isModalChooseProductOpen} testId='modal-cmp'>
      <div className={style['cmp-wrapper']}>
        <div className={style['cmp']}>
          <div className={style['cmp__close-cta']}>
            <IconButton icon={<Close20Icon />} testId='btn-cmp-close' onClick={closeModal} />
          </div>
          <div className={style['cmp__close-title']}>
            <Text typography='heading3'>
              Pilih layanan :
            </Text>
          </div>
          {
            productPinfinanceModal.map((product: ProductPinfinanceModalItem, index: number) => {
              return (
                <a
                  href={isMobile ? product.ctaLinkMobile : product.ctaLinkDesktop}
                  className={style['cmp__product-card']}
                  data-testid='cmp-product-card'
                  key={index}
                  onClick={() => handleSingInOnClicked(product)}>
                  <Image
                    src={product.icon}
                    height={100} width={100}
                    alt={`${product.title.trim().replace(/ /g, '-').toLowerCase()}-icon`} />
                  <span className={style['cmp__product-card-name']}>
                    <Text typography='label1'>
                      {product.title}
                    </Text>
                  </span>
                </a>
              );
            })
          }
        </div>
      </div>
    </Popup>
  );
};

export default ChooseProductModal;