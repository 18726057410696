import internalUrls from '@/constants/internalUrls';

import type { ProductPinfinanceModalItem } from './types';

export const productPinfinanceModal: ProductPinfinanceModalItem[] = [
  {
    icon: 'https://pinhome-web-assets.imgix.net/icon-komisi-instan.png',
    title: 'Komisi Instan',
    ctaLinkDesktop: 'https://play.google.com/store/apps/details?id=id.pinhome.agent&hl=en&gl=US',
    ctaLinkMobile: 'https://rekan.pinhome.id/app/komisi-instan'
  },
  {
    icon: 'https://pinhome-web-assets.imgix.net/icon-bridging-loan.png',
    title: 'Bridging Loan',
    ctaLinkDesktop: internalUrls.login,
    ctaLinkMobile: internalUrls.login
  }
];
