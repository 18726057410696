import { useContext } from 'react';

import type { ParsedUserAgent } from '@/types/general/parsedUserAgent';

import { ParsedUserAgentContext } from './context';

export function useParsedUserAgent(): ParsedUserAgent {
  const parsedUserAgent = useContext(ParsedUserAgentContext);

  if (typeof parsedUserAgent === 'undefined') {
    throw new Error('`useParsedUserAgent` was used outside of `ParsedUserAgentProvider`');
  }

  return parsedUserAgent;
}
