import { useMemo } from 'react';

import { ButtonPrimary } from '@pinvest/sirius-button';
import Image from 'next/image';

import Text from '@/components/shared/Text';
import { waPinfinance2 } from '@/constants/externalUrls';
import { useParsedUserAgent } from '@/contexts/parsedUserAgent';

import { illustrationHelp } from './config';
import styles from './styles.module.css';
import type { HelpSectionProps } from './types';

const HelpSection = ({
  title,
  description,
  onClickButtonContactUs
}: HelpSectionProps) => {
  const { isMobile } = useParsedUserAgent();

  const imageHelp = useMemo(() => {
    if (!isMobile) {
      return (
        <Image className={styles['help__container-icon']} src={illustrationHelp} alt='icon' width={591} height={295} />
      );
    }

    return null;
  }, [isMobile]);

  return (
    <section className={styles.help}>
      <div className={styles.help__container}>
        {imageHelp}
        <div className={styles.help__copy}>
          <Text typography='display2' component='h1'>{title}</Text>
          <Text typography='body1' component='p' margin='16px 0 41px 0'>
            {description}
          </Text>
          <div className={styles.button}>
            <ButtonPrimary
              fullWidth={isMobile}
              href={waPinfinance2}
              target='_blank'
              size='large'
              onClick={onClickButtonContactUs}
              testId='btn-hubungiKami-helpSection'
            >
              Hubungi Kami
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HelpSection;
