import cx from 'clsx';
import Image from 'next/image';

import Text from '@/components/shared/Text';

import { partnerLogos } from './config';
import styles from './styles.module.css';
import type { PartnerSectionProps } from './types';

const PartnerSection = ({ partnerDescription, partnersImage, backgroundColor = 'default' }: PartnerSectionProps) => {
  return (
    <section className={cx(styles.partner, styles[backgroundColor])}>
      <div className={styles.partner__container}>
        <div className={styles.partner__container_item}>
          <Text typography='display2' component='h1'>Partner Kami Sudah Terdaftar dan Diawasi Oleh OJK</Text>
          <Text typography='body1' component='p' margin='16px 0 0 0'>
            {partnerDescription
              ? partnerDescription
              : 'Layanan Pinhome Financial didukung oleh partner terpercaya yang sudah terdaftar dan diawasi oleh OJK'
            }
          </Text>
        </div>
        <div className={styles.partner__container_item}>
          <div className={styles.partner__container_item_image}>
            <Image src={partnersImage ? partnersImage : partnerLogos}
              alt='partner' width='2'
              height='1' objectFit='contain'
              layout='responsive' />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PartnerSection;
