import { useCallback } from 'react';

import { ButtonPrimary } from '@pinvest/sirius-button';
import Image from 'next/image';

import Text from '@/components/shared/Text';

import { benefitList, pinhomeLogo } from './config';
import styles from './styles.module.css';

const BriefDescBenefits: React.FC = () => {

  const handleProductCTA = useCallback(() => {
    const el = document.getElementById('product-section');

    if (!!el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <section>
      <div className={styles.about_pinfinance}>
        <div className={styles.container}>
          <div className={styles.title_content}>
            <Text typography='display2'>Apa Itu Pinhome Financial?</Text>
          </div>
          <div>
            <Text>Pinhome Financial adalah salah satu produk dari Pinhome,
              platform e-commerce jual beli sewa properti dan layanan rumah tangga pertama dan tepercaya di Indonesia.
              Layanan Pinhome Financial hadir untuk memberikan dukungan finansial kepada para pelaku usaha properti
            </Text>
          </div>
          <div className={styles.button_container}>
            <ButtonPrimary size='large' onClick={handleProductCTA}>Cek Produk Kami</ButtonPrimary>
          </div>
        </div>
        <div className={styles.logo}>
          <Image src={pinhomeLogo} layout='responsive' width={10} height={10} alt='' />
        </div>
      </div>
      <div className={styles.benefit}>
        <div className={styles.benefit_container}>
          <div className={styles.grid_container}>
            {
              benefitList.map((data, index) => {
                return (
                  <div className={styles.card} key={index}>
                    <div className={styles.card_content}>
                      <div className={styles.icon_container}>
                        <Image src={data.icon} layout='responsive' width={92} height={92} alt='icon transparency transaction' />
                      </div>
                      <div>
                        <Text component='h5' typography='heading3'>{data.title}</Text>
                        <Text typography='body1' color='disabled'>{data.desc}</Text>
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </section>
  );
};

export default BriefDescBenefits;
