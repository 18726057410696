import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonOutline, ButtonPrimary } from '@pinvest/sirius-button';
import dynamic from 'next/dynamic';

import HelpSection from '@/components/shared/LandingPage/HelpSection';
import PartnerSection from '@/components/shared/LandingPage/PartnerSection';
import MetaHead from '@/components/shared/MetaHead';
import NavigationBar from '@/components/shared/NavigationBar';
import AppTemplate from '@/components/shared/templates/AppTemplate';
import { useOAuth } from '@/contexts/oauth';
import { useParsedUserAgent } from '@/contexts/parsedUserAgent';
import cookie from '@/services/cookies';
import EventTrackingService from '@/services/eventTracking';
import { pinfinanceDiscoveryClickButton, pinfinanceDiscoveryOpenPage } from '@/services/eventTracking/events/pinfinanceDiscovery';
import {
  PinfinanceDiscoveryAttr,
  PinfinanceDiscoveryButtonName,
  PinfinanceDiscoveryButtonNameAttr,
  PinfinanceDiscoveryOpenPageName
} from '@/services/eventTracking/events/pinfinanceDiscovery/types';

import BriefDescBenefits from './components/BriefDescBenefits';
import ChooseProductModal from './components/ChooseProductModal';
import Header from './components/Header';
import ProductSection from './components/ProductSection';
import Testimonial from './components/Testimonials';
import { metaDescription, metaTitle, pinfinanceHelpSection } from './config';
import style from './style.module.css';
import type { HomePageProps } from './types';
const ACDB2BPage = dynamic(() => import('@/components/pages/ACDB2BPage'));

export default function HomePage({
  featureFlagConfig,
  isEligibleSubmission
}: HomePageProps) {
  const [isModalChooseProductOpen, setIsModalChooseProductOpen] = useState(false);

  const { cta_login_register_navbar, enable_b2b_acd } = featureFlagConfig;

  const { isMobile } = useParsedUserAgent();
  const { user } = useOAuth();

  const modalChooseProductToogler = useCallback((val: boolean) => {
    setIsModalChooseProductOpen(val);
  }, []);

  useEffect(() => {
    const res = cookie.get(PinfinanceDiscoveryOpenPageName.HOMEPAGE);

    cookie.delete(PinfinanceDiscoveryOpenPageName.HOMEPAGE);

    const parsedRes = res ? JSON.parse(res) : null;
    if (!parsedRes?.attribute) {
      EventTrackingService.pushEvent(pinfinanceDiscoveryOpenPage(
        {
          page_name: PinfinanceDiscoveryOpenPageName.HOMEPAGE,
          attribute: PinfinanceDiscoveryAttr.DIRECT_LINK
        }
      ));
      return;
    }

    EventTrackingService.pushEvent(pinfinanceDiscoveryOpenPage(
      {
        page_name: PinfinanceDiscoveryOpenPageName.HOMEPAGE,
        attribute: parsedRes.attribute
      }
    ));
  }, []);

  const handleApplyButtonClickNavbar = useCallback(() => {
    const el = document.getElementById('product-section');

    if (!!el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleLoginRegisButtonClickNavbar = useCallback(() => {
    setIsModalChooseProductOpen(true);
  }, []);

  const applyPinfinanceButton = useMemo(() => {
    return (
      <ButtonPrimary testId='btn-ajukan-navbar' onClick={handleApplyButtonClickNavbar}>Ajukan Sekarang</ButtonPrimary>
    );
  }, [handleApplyButtonClickNavbar]);

  const extraNavigationItems = useMemo(
    () => {
      return (
        <div className={style['homepage__extra-navbar']}>
          {cta_login_register_navbar &&
            <div className={style['homepage__extra-navbar-item']}>
              <ButtonOutline testId='btn-masukDaftar-navbar' onClick={handleLoginRegisButtonClickNavbar}>Masuk/Daftar</ButtonOutline>
            </div>
          }

          {(!isMobile || (isMobile && !cta_login_register_navbar)) &&
            <div className={style['homepage__extra-navbar-item']}>
              {applyPinfinanceButton}
            </div >
          }
        </div>

      );
    }, [applyPinfinanceButton, cta_login_register_navbar, handleLoginRegisButtonClickNavbar, isMobile]);

  const extraNavigationItemsOverlay = useMemo(
    () => {
      return (
        <div className={style['homepage__extra-navbar_overlay']}>
          <div className={style['homepage__extra-navbar-item']}>
            {applyPinfinanceButton}
          </div >
        </div>

      );
    }, [applyPinfinanceButton]);

  const onClickButtonHelpContactUs = useCallback(() => {
    EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
      page_name: PinfinanceDiscoveryOpenPageName.HOMEPAGE,
      attribute: PinfinanceDiscoveryButtonNameAttr.HELP_BANNER,
      button_name: PinfinanceDiscoveryButtonName.CONTACT_US
    }));
  }, []);

  if (enable_b2b_acd) {
    return <ACDB2BPage isEligibleSubmission={isEligibleSubmission} />;
  }

  return (
    <AppTemplate>
      <MetaHead title={metaTitle} description={metaDescription} />
      <NavigationBar extraNavigationItems={!user && (extraNavigationItems)} extraNavigationItemsOverlay={extraNavigationItemsOverlay} />
      <Header />
      <ChooseProductModal isModalChooseProductOpen={isModalChooseProductOpen} modalChooseProductToogler={modalChooseProductToogler} />
      <BriefDescBenefits />
      <ProductSection
        ctaAjukanBlRegister={featureFlagConfig.cta_ajukan_bl_register}
        enableRedirectFormSubmission={featureFlagConfig.enable_redirect_form_submission_bl}
      />
      <Testimonial />
      <HelpSection
        title={pinfinanceHelpSection.title}
        description={pinfinanceHelpSection.description}
        onClickButtonContactUs={onClickButtonHelpContactUs}
      />
      <PartnerSection />
    </AppTemplate>
  );
};
