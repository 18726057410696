import React, { useMemo } from 'react';

import Head from 'next/head';

import { removedHtmlTagRegex } from '@/utils/regex/commonRegex';

import type { MetaProps } from './types';

const MetaHead = ({
  title, description
}: MetaProps) => {
  const metaTitle = useMemo(() => title && (
    <>
      <title>{title}</title>
      <meta
        property="og:title"
        content={title}
      />
      <meta
        name="twitter:title"
        content={title}
      />
    </>
  ), [title]);

  const metaDescription = useMemo(() => {
    const descriptionText = (description ?? '').replace(removedHtmlTagRegex, '');

    if (!descriptionText) return null;

    return (
      <>
        <meta
          name="description"
          content={descriptionText}
        />
        <meta
          property="og:description"
          content={descriptionText}
        />
        <meta
          name="twitter:description"
          content={descriptionText}
        />
      </>
    );
  }, [description]);


  return (
    <Head>
      {metaTitle}

      {metaDescription}
    </Head>
  );
};


export default MetaHead;
