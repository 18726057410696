export enum SignUpAttr {
  SIGN_IN_FORM = 'sign_in_form',
  SERVICE_SELECTION_BRIDGING_LOAN_APPLY = 'service_selection_bridging_loan_apply',
  NAV_BAR_BRIDGING_LOAN_APPLY = 'nav_bar_bridging_loan_apply',
  HERO_BANNER_BRIDGING_LOAN_APPLY = 'hero_banner_bridging_loan_apply',
  BRIDGING_LOAN_PRODUCT_SELECTION = 'bridging_loan_product_selection',
  BOTTOM_BANNER_BRIDGING_LOAN_APPLY = 'bottom_banner_bridging_loan_apply',
  SIMULATION_BRIDGING_LOAN_APPLY = 'simulation_bridging_loan_apply'
}

export type SignUpOpenPage = {
  attribute: SignUpAttr | string;
};

export type SignUpClickButton = {
  attribute: SignUpAttr | string;
  customer_type: 'business' | 'personal';
  office_business_type?: 'limited_company' | 'limited_partnership' | 'others' | null;
  customer_name: string;
  customer_email: string;
  customer_phone_number: string;
  verification_type: 'whatsapp' | 'sms';

};

export type SignUpGetResponse = {
  customer_guid: string;
  attribute: SignUpAttr | string;
};
