import { useCallback } from 'react';

import { ButtonOutline, ButtonPrimary } from '@pinvest/sirius-button';
import Image from 'next/image';

import Check from '@/components/shared/Icon/Check';
import Text from '@/components/shared/Text';
import { typeFormBL } from '@/constants/externalUrls';
import internalUrls from '@/constants/internalUrls';
import { useOAuth } from '@/contexts/oauth';
import { useParsedUserAgent } from '@/contexts/parsedUserAgent';
import cookie from '@/services/cookies';
import EventTrackingService from '@/services/eventTracking';
import { pinfinanceDiscoveryClickButton } from '@/services/eventTracking/events/pinfinanceDiscovery';
import {
  PinfinanceDiscoveryAttr,
  PinfinanceDiscoveryButtonName,
  PinfinanceDiscoveryButtonNameAttr,
  PinfinanceDiscoveryOpenPageName
} from '@/services/eventTracking/events/pinfinanceDiscovery/types';
import { signUpEvent } from '@/services/eventTracking/events/signUp/constants';
import { SignUpAttr } from '@/services/eventTracking/events/signUp/types';

import { productPinfinance } from './config';
import styles from './styles.module.css';
import type { ProductPinfinanceItem, ProductSectionProps } from './types';
import { ProductPinfinance } from './types';

const createButtonLearnMoreHandler = (productLink: string) => {
  return () => {
    if (productLink === '/komisi-instan') {
      cookie.add({
        name: PinfinanceDiscoveryOpenPageName.ACD_HOMEPAGE,
        value: JSON.stringify({ attribute: PinfinanceDiscoveryAttr.ACD_LEARN_MORE })
      });
    }

    if (productLink === '/bridging-loan') {
      cookie.add({
        name: PinfinanceDiscoveryOpenPageName.BRIDGING_LOAN_HOMEPAGE,
        value: JSON.stringify({ attribute: PinfinanceDiscoveryAttr.BRIDGING_LOAN_LEARN_MORE })
      });
    }
  };
};

const ProductSection: React.FC<ProductSectionProps> = ({
  ctaAjukanBlRegister = false,
  enableRedirectFormSubmission = false
}) => {
  const { isMobile } = useParsedUserAgent();
  const { user, isEligibleSubmission } = useOAuth();

  const onClickButtonAjukanACD = useCallback((isACDProduct: boolean) => {
    return () => {
      if (isACDProduct) {
        EventTrackingService.pushEvent(pinfinanceDiscoveryClickButton({
          page_name: PinfinanceDiscoveryOpenPageName.HOMEPAGE,
          attribute: PinfinanceDiscoveryButtonNameAttr.SERVICE_SELECTION,
          button_name: PinfinanceDiscoveryButtonName.ACD_SUBMISSION
        }));
        return;
      }

      if (enableRedirectFormSubmission && !user) {
        cookie.add({
          name: signUpEvent.openPage,
          value: JSON.stringify({ attribute: SignUpAttr.SERVICE_SELECTION_BRIDGING_LOAN_APPLY })
        });
      }
    };
  }, [enableRedirectFormSubmission, user]);

  const hrefAjukan = useCallback((product: ProductPinfinance, linkDesktop: string, linkMobile: string) => {
    if (product == ProductPinfinance.ACD) {
      return isMobile ? linkMobile : linkDesktop;
    }

    if (enableRedirectFormSubmission) {
      if (user) {
        if (isEligibleSubmission) {
          return linkDesktop;
        }

        return internalUrls.profile;
      }

      if (ctaAjukanBlRegister) {
        return internalUrls.register;
      }
    }

    return typeFormBL;
  }, [enableRedirectFormSubmission, isMobile, user, ctaAjukanBlRegister, isEligibleSubmission]);

  return (
    <section className={styles.base} id='product-section'>
      <div className={styles.base_container}>
        <Text typography='display2' component='h1'>Pilihan Jenis Layanan Terbaik Untuk Anda</Text>
        <div className={styles.margin_top2}>
          <Text typography='body1' component='p'>Pinhome Financial menyediakan
            beragam layanan finansial terbaik khusus untuk Anda para pelaku usaha
            properti yang sudah menjadi bagian dari ekosistem Pinhome.
          </Text>
        </div>
        <div className={styles.product_explaination}>
          {
            productPinfinance.map((data: ProductPinfinanceItem, index) => {
              return (
                <div key={index} style={{ position: 'relative' }}>
                  <div className={styles.title_product_container}>
                    <div className={styles.icon}>
                      <Image src={data.icon} alt='icon' layout='responsive' width={20} height={20} />
                    </div>
                    <div className={styles.title_product}>
                      <Text typography='display3' component='h2'>{data.title}</Text>
                      <Text typography='body1' color='subtle' margin='8px 0 0 0' component='p'>
                        {data.desc}
                      </Text>
                    </div>
                  </div>
                  <div>
                    {
                      data.detailsItem.map((item) => {
                        return (
                          <div key={item} className={styles.details}>
                            <Check />
                            <Text>{item}</Text>
                          </div>
                        );
                      })
                    }
                  </div>
                  <div className={styles.button_container}>
                    <ButtonOutline
                      href={data.detailLink}
                      fullWidth={true} size='large'
                      onClick={createButtonLearnMoreHandler(data.detailLink)}
                    >
                      Pelajari Lebih Lanjut
                    </ButtonOutline>
                    <ButtonPrimary
                      href={hrefAjukan(data.product, data.ctaAjukanLinkDesktop, data.ctaAjukanLinkMobile)}
                      size='large'
                      target={data.product == ProductPinfinance.ACD ? '_blank' : undefined}
                      fullWidth={true}
                      testId={`btn-product-${data.product}`}
                      onClick={onClickButtonAjukanACD(data.detailLink == '/komisi-instan')}
                    >
                      Ajukan Sekarang
                    </ButtonPrimary>
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </section>
  );
};


export default ProductSection;
