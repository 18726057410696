import type { EventTrackingParam } from '@/services/eventTracking/types';

import { pinfinanceDiscoveryEvent } from './constants';
import type { PinfinanceDiscoveryClickButtonParam, PinfinanceDiscoveryOpenPage } from './types';

export function pinfinanceDiscoveryOpenPage(
  params: PinfinanceDiscoveryOpenPage
): EventTrackingParam {
  return {
    event: pinfinanceDiscoveryEvent.openPage,
    eventProps: {
      ...params
    }
  };
}

export function pinfinanceDiscoveryClickButton(
  params: PinfinanceDiscoveryClickButtonParam
): EventTrackingParam {
  return {
    event: pinfinanceDiscoveryEvent.clickButton,
    eventProps: {
      ...params
    }
  };
}
