import React, { useCallback } from 'react';

import { ButtonPrimary } from '@pinvest/sirius-button';
import Image from 'next/image';

import Text from '@/components/shared/Text';

import { PromoImage } from './config';
import styles from './styles.module.css';

const Header: React.FC = () => {

  const handleProductCTA = useCallback(() => {
    const el = document.getElementById('product-section');

    if (!!el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={styles.header}>
      <div className={styles.header_content}>
        <div className={styles.text_banner}>
          <div className={styles.title__content}>
            <Text typography='label1' color='primary'>Pinhome Financial</Text>
          </div>
          <div className={styles.margin_bottom2}>
            <Text typography='display1'>Dukungan Finansial Untuk Pelaku Usaha Properti</Text>
          </div>
          <div className={styles.margin_bottom4}>
            <Text>
              Solusi untuk memenuhi kebutuhan finansial properti Anda. Bekerja sama dengan partner tepercaya dan diawasi oleh OJK
            </Text>
          </div>
          <div className={styles.button__container}>
            <ButtonPrimary type='button' size='large' onClick={handleProductCTA}>Cek Produk Kami</ButtonPrimary>
          </div>
        </div>
        <div className={styles.background_image_banner}>
          <Image src={PromoImage} layout='responsive' width={700} height={653} alt="banner" />
        </div>
      </div>
    </div>
  );
};

export default Header;
