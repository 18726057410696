export interface ProductSectionProps {
  ctaAjukanBlRegister?: boolean;
  enableRedirectFormSubmission?: boolean;
}

export enum ProductPinfinance {
  BL = 'bl',
  ACD = 'acd'
}

export type ProductPinfinanceItem = {
  product: ProductPinfinance;
  icon: string;
  title: string;
  desc: string;
  detailsItem: string[];
  ctaAjukanLinkDesktop: string;
  ctaAjukanLinkMobile: string;
  detailLink: string;
};
