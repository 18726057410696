export const pinhomeLogo = 'https://pinhome-web-assets.imgix.net/pinhome-logo-transparent-cropted.png';

export type Benefit = {
  icon: string;
  title: string;
  desc: string;
};
export const benefitList: Benefit[] = [
  {
    icon: 'https://pinhome-web-assets.imgix.net/icon-transaction-transparency.png',
    title: 'Proses Transaksi Transparan',
    desc: 'Semua tahapan proses dapat dipantau melalui platform digital'
  },
  {
    icon: 'https://pinhome-web-assets.imgix.net/icon-service-fee.png',
    title: 'Biaya Layanan Murah',
    desc: 'Biaya layanan yang kompetitif untuk para pengguna Pinhome Financial'
  },
  {
    icon: 'https://pinhome-web-assets.imgix.net/icon-fast-disbursement.png',
    title: 'Pencairan Dana Cepat',
    desc: 'Proses mudah, aman, dan cepat untuk mendukung kebutuhan Anda'
  },
  {
    icon: 'https://pinhome-web-assets.imgix.net/icon-tenure.png',
    title: 'Pilihan Tenor Beragam',
    desc: 'Pilihan tenor dapat disesuaikan dengan kebutuhan finansial Anda'
  }
];
