export enum SignInAttr {
  SIGN_UP_FORM = 'sign_up_form',
  NAV_BAR_SIGN_IN = 'nav_bar_sign_in'
}

export type SignInOpenPage = {
  attribute: SignInAttr | string;
};

export type SignInGetResponse = {
  customer_guid: string;
  attribute: SignInAttr | string;
};
